import toast from "react-hot-toast";

class Api {
  constructor() {
    this.server = "https://bmimioc.ru";
  }

  async getUsers(password) {
    try {
      const response = await fetch(`${this.server}/api/getUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.users;
      } else {
        toast.error(data.errMsg || "error");
        return [];
      }
    } catch (e) {
      toast.error("error");
      return [];
    }
  }

  async getUser(password, id) {
    try {
      const response = await fetch(`${this.server}/api/getUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          password,
          id,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.user;
      } else {
        toast.error(data.errMsg || "error");
        return {};
      }
    } catch (e) {
      toast.error("error");
      return {};
    }
  }

  async setUserDeactivate(password, id, deactivate) {
    try {
      const response = await fetch(`${this.server}/api/setUserDeactivate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          id,
          deactivate,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.status;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (error) {
      toast.error("error");
      return false;
    }
  }
  async setUserCoin(password, id, coin) {
    try {
      const response = await fetch(`${this.server}/api/setUserCoin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          id,
          coin,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.status;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (error) {
      toast.error("error");
      return false;
    }
  }
  async getOrder(password, idOrder) {
    try {
      const response = await fetch(`${this.server}/api/getOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          idOrder,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.order;
      } else {
        toast.error(data.errMsg || "error");
        return {};
      }
    } catch (error) {
      toast.error("error");
      return {};
    }
  }
  async getWallets(password) {
    try {
      const response = await fetch(`${this.server}/api/getWallets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.wallets;
      } else {
        toast.error(data.errMsg || "error");
        return [];
      }
    } catch (e) {
      toast.error("error");
      return [];
    }
  }
  async addWallet(password, seed) {
    try {
      const response = await fetch(`${this.server}/api/addWallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          seed,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.status;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (e) {
      toast.error("error");
      return false;
    }
  }
  async deleteWallet(password, id) {
    try {
      const response = await fetch(`${this.server}/api/deleteWallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          id,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.status;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (e) {
      toast.error("error");
      return false;
    }
  }

  async getPrices(password) {
    try {
      const response = await fetch(`${this.server}/api/getPrices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.prices;
      } else {
        toast.error(data.errMsg || "error");
        return [];
      }
    } catch (e) {
      toast.error("error");
      return [];
    }
  }
  async setPrice(password, id, value) {
    try {
      const response = await fetch(`${this.server}/api/setPrice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          id,
          value,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.status;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (error) {
      toast.error("error");
      return false;
    }
  }
  async getStatistics(password) {
    try {
      const response = await fetch(`${this.server}/api/getStatistics`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await response.json();
      if (data.status) {
        return data.orders;
      } else {
        toast.error(data.errMsg || "error");
        return data.status;
      }
    } catch (error) {
      toast.error("error");
      return false;
    }
  }
}

export const api = new Api();
