import { Toaster } from 'react-hot-toast';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Password from './Password';
import Users from './Users';
import Links from './Links'
import Wallets from './Wallets';
import Prices from './Prices';
import Order from './Order';
import Statistics from './Statistics';

function App() {
  return (
    <div className="App">
      <Password></Password>
      <Links></Links>
      <Routes>
        <Route path="/" element={<Users></Users>}></Route>
        <Route path="/users" element={<Users></Users>}></Route>
        <Route path="/wallets" element={<Wallets></Wallets>}></Route>
        <Route path="/statistics" element={<Statistics></Statistics>}></Route>
        <Route path="/price" element={<Prices></Prices>}></Route>
        <Route path="/order/:userid/:orderid" element={<Order></Order>}></Route>
      </Routes>
      <Toaster position="bottom-right"></Toaster>
    </div>
  );
}

export default App;
